import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Divider from '../components/divider.jsx';
import SectionTitle from '../components/sectiontitle.jsx';
import Section from '../components/section.jsx';
import BookCta from '../components/book-cta.js';
import Subscribe from '../components/subscribe.js';

const formatDateTime = (dateTime) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDateTime = new Date(`${dateTime}`);
  return formattedDateTime.toLocaleDateString("en-US", options);
}

export default function Blogpost({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark


  return (
    <Layout currentPage={[{title:"Blog", url:""},{title: frontmatter.title, url: `/blog/${frontmatter.path}`}]}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={frontmatter.title}
        blogPostPath={frontmatter.path}
        description={frontmatter.description}
      />

      <Section>
        <article>
          <header className="mb-6">
            <SectionTitle isH1={true} title={frontmatter.title}/>
            <time dateTime={frontmatter.date} className="text-xs text-gray-600 mb-8 leading-none">{formatDateTime(frontmatter.date)}</time>
          </header>
          <Divider/>
          <div
            className="blog-post prose prose-lg"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </Section>
      <Divider/>
      {/* <BookCta/> */}
      {/* <Divider/> */}
      <Subscribe/>
      <Divider/>
      <div className="mb-6">
        <Link to="/">&larr; Home</Link>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        description
        path
      }
    }
  }
`