import React, { useState } from "react";
import withLocation from "./withLocation";

function Subscribe({ queryParams, ctaText }) {
  async function handleSubmit() {
    if (!email) {
      return;
    }
    const values = {
      email: email,
      api_key: `FtS5tTmwMNaOEjLbFkSiCQ`,
    };
    setSubmitted(true);
    try {
      const response = await fetch(
        `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
        {
          method: `post`,
          body: JSON.stringify(values, null, 2),
          headers: {
            Accept: `application/json`,
            "Content-Type": `application/json`,
          },
        }
      );

      const responseJson = await response.json();

      setSubmitted(true);
      setResponse(responseJson);
      setErrorMessage(responseJson.error);
      trySendFathom();
    } catch (error) {
      setSubmitted(false);
      setErrorMessage(`Something went wrong!`);
    }
  }

  function trySendFathom() {
    if (window.fathom) {
      window.fathom.trackGoal(`JSRYWETA`, 0);
    }
  }

  const { confirmed } = queryParams;
  const justConfirmedEmail = confirmed === `true`;

  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState(``);
  const FORM_ID = `1417467`;
  const isLoading = submitted && !response && !errorMessage;
  const success =
    justConfirmedEmail || (submitted && response && !errorMessage);
  const failure = errorMessage;
  const successText = justConfirmedEmail
    ? `Success! You're on the list.`
    : `Success! Check your email to confirm.`;

  const buttonText = () => {
    if (success) return `Success!`;
    if (failure) return `Error`;
    return `Subscribe`;
  };

  return (
    <div className="relative">
      <div className="sm:text-center">
        <p className="mt-6 mx-auto max-w-2xl text-lg text-blue-700 font-semibold">
          {ctaText
            ? ctaText
            : `Join my newsletter to get more posts like this:`}
        </p>
      </div>
      <div className="mt-4 sm:mx-auto sm:max-w-lg sm:flex">
        <div className="min-w-0 flex-1">
          <label className="sr-only" htmlFor="cta-email">
            Email address
          </label>
          <input
            className={`block w-full border border-slate-300 rounded-md px-5 py-3 text-base  placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600
            ${
              success
                ? `bg-emerald-500 border-emerald-500 text-white font-bold`
                : `bg-white text-gray-900`
            }
            `}
            id="cta-email"
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Enter your email"
            type="email"
            value={success ? successText : email}
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-3">
          <button
            className={`
            block w-full rounded-md border border-transparent px-5 py-3 text-base font-medium  shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500 sm:px-10
            ${isLoading ? `bg-gray-500` : `text-gray-100`}
            ${success ? `bg-emerald-500 text-white` : ``}
            ${failure ? `bg-red-500` : `text-white`}
            ${
              !isLoading && !success && !failure
                ? `bg-blue-700 hover:bg-blue-600 text-white`
                : ``
            }
            `}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {buttonText()}
          </button>
        </div>
      </div>
    </div>
  );
}

export default withLocation(Subscribe);
