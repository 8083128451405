import React from 'react'


function BookCta() {

   return (
    <div className="mt-4 py-2">

      <p className="text-center text-deepblack my-0">
        I just released my book about Adult ADHD
        <br/>
        <span className="font-bold">Check it out: <a href="https://adhdpro.xyz/" className="nicelink">ADHD Pro</a></span>
      </p>
    </div>
  )
}

export default BookCta;
